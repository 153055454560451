<template>
  <v-container>
    <v-data-table
        dense
        :headers="headers"
        :items="list"
        item-key="name"
        class="elevation-1"
    >
      <template v-slot:item.exam_score="{ item }">
        {{item.exam_score>=0&&item.exam_score!=null?item.exam_score:'Chưa chấm điểm'}}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          color="primary"
          rounded
          height="25px"
          @click="markExam(item)"
        >
          Chấm điểm
        </v-btn>
      </template>
      <template v-slot:no-data>
        <div>
          <v-alert color="warning" icon="priority_high" outlined>
            Danh sách bài thí sinh yêu cầu chấm điểm
          </v-alert>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { GET_LIST_STUDENT_ẸNJOY_EXAM } from "@/store/exams.module";

export default {
  name: "list",
  data: () => ({
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id"
      },
      { text: "Tên thí sinh", value: "user.full_name" },
      { text: "Ngày sinh", value: "user.birthday" },
      { text: "Số điên thoại", value: "user.phone_number" },
      { text: "Điểm bài viết", value: "exam_score", align: "center" },
      { text: "", value: "action" }
    ]
  }),
  computed: {
    list() {
      return this.$store.getters.getListStudentEnjoyExam;
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$store.dispatch(GET_LIST_STUDENT_ẸNJOY_EXAM, {
        exam_schedule_id: this.$route.query.exam_schedule_id,
      });
    },
    markExam(item) {
      this.$router.push({
        path: "markExam",
        query: {
          exam_schedule_id: item.exam_schedule_id,
          exam_id: item.exam_id,
          exam_config_id: item.exam_config_id,
          user_id: item.user_id,
        },
      });
    }
  }
};
</script>

<style scoped></style>
